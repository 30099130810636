import { useMemo, useRef, useState } from 'react'
import { useProductDialog } from 'services/product-dialog'
import { Box, Grid } from '@mui/material'
import ProductModalPagination from 'components/product-modal-pagination/ProductModalPagination'
import ProductModalControls from 'components/product-modal-controls/ProductModalControls'
import ProductModalGallery from 'components/product-modal-gallery/ProductModalGallery'
import ProductModalSummary from 'components/product-modal-summary/ProductModalSummary'
import ProductModalAbout from 'components/product-modal-about/ProductModalAbout'
import ProductModalDetails from 'components/product-modal-details/ProductModalDetails'
import ProductModalPriceList from 'components/product-modal-pricelist/ProductModalPricelist'
import ProductModalAddToCart from 'components/product-modal-add-to-cart/ProductModalAddToCart'
import ProductModalNotification from 'components/product-modal-notification/ProductModalNotification'

const ProductModal = ({ cuppingScore }) => {
  const priceListRef = useRef(null)
  const { product } = useProductDialog()
  const [notification, setNotification] = useState(null)

  const { location, cupping_notes, score, batch, bags, availability_date } = product

  const certificates = useMemo(() => {
    const certs = []
    for (const [key, value] of Object.entries(product)) {
      if (key.includes('cert_') && value) {
        certs.push(key.replace('cert_', '')?.toUpperCase())
      }
    }
    return certs
  }, [product])

  const scrollToPricing = () => {
    if (priceListRef?.current) {
      priceListRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const notify = (string) => setNotification(string)

  return (
    <Box sx={{ width: 800, maxWidth: '100%' }}>
      <ProductModalPagination />
      <Box key={product.batch} display='flex' flexDirection='column' gap={2}>
        <ProductModalNotification
          notification={notification}
          setNotification={setNotification}
        />
        <Box position='relative'>
          <ProductModalControls
            title={product?.location?.name}
            pbo={batch}
            notify={notify}
          />
          <Grid container columnSpacing={3}>
            <Grid item xs={12} md={6}>
              <ProductModalGallery
                images={product?.images}
                fallbackImage={product.fallback_url}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <ProductModalSummary
                product={product}
                introduction={cupping_notes.join(', ')}
                cuppingScoreChip={cuppingScore(score)}
                certificates={certificates}
                notify={notify}
              />
            </Grid>
          </Grid>
        </Box>
        <ProductModalAbout location={location} />
        <ProductModalDetails product={product} certificates={certificates} />
        <ProductModalPriceList
          ref={priceListRef}
          pricingData={product?.pricingData}
          availability={bags}
          availabilityDate={availability_date}
        />
        <ProductModalAddToCart
          title={location.name}
          product={product}
          scrollToPricing={scrollToPricing}
          notify={notify}
          availabilityDate={availability_date}
        />
      </Box>
    </Box>
  )
}

export default ProductModal
