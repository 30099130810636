import { Box, IconButton, useTheme, useMediaQuery } from '@mui/material'
import { useProductDialog } from '../../services/product-dialog'
import NativeShare from 'components/native-share/NativeShare'
import CloseIcon from '@mui/icons-material/Close'

const ProductModalControls = ({ title, pbo, notify }) => {
  const { closeDialog } = useProductDialog()
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Box
      display='flex'
      gap={1}
      position='absolute'
      top={mobile ? 12 : 0}
      right={mobile ? 12 : 0}
      zIndex={99}
    >
      <NativeShare title={title} pbo={pbo} notify={notify} />
      <IconButton onClick={closeDialog} sx={{ background: '#FFF' }}>
        <CloseIcon />
      </IconButton>
    </Box>
  )
}

export default ProductModalControls
