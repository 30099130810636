import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { ModalProvider } from 'services/modal'
import { ProductDialogProvider } from 'services/product-dialog'
import { useSharedState } from 'store'
import AppRouter from 'components/app-router'
import theme from 'theme'
import 'assets/fonts/fonts.css'
import { GoogleTagManagerProvider } from 'utils/helpers/gtm'

function App() {
  const auth = useSharedState('auth')

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <GoogleTagManagerProvider>
        <ModalProvider>
          <ProductDialogProvider>
            <AppRouter auth={auth} />
          </ProductDialogProvider>
        </ModalProvider>
      </GoogleTagManagerProvider>
    </ThemeProvider>
  )
}

export default App
