import { useMemo, forwardRef } from 'react'
import { Box, Typography } from '@mui/material'
import { PriceList, PriceListItem, Surface } from './styles'

const ProductModalPriceList = forwardRef(
  ({ pricingData, availability, availabilityDate }, ref) => {
    const pricingList = useMemo(() => {
      const list = []
      for (const [key, value] of Object.entries(pricingData.pricesFormatted)) {
        list.push({ qty: key, price: value })
      }

      return list.map(({ qty, price }) => (
        <PriceListItem key={qty}>
          <Typography>{qty}+</Typography>
          <Typography sx={{ fontWeight: 600 }}>{price}</Typography>
        </PriceListItem>
      ))
    }, [pricingData.pricesFormatted])

    return (
      <Surface ref={ref}>
        <Typography variant='h3' mb={1.5}>
          Price per bag
        </Typography>
        <PriceList>
          {pricingList}
          <PriceListItem>
            <Typography>Availability</Typography>
            <Box display='flex' alignItems='center' gap={1}>
              <Box
                width={10}
                height={10}
                sx={{
                  bgcolor: availabilityDate ? 'pine.main' : 'success.main',
                  borderRadius: '50%'
                }}
              />
              <Typography sx={{ fontWeight: 600 }}>{availability} bags</Typography>
            </Box>
          </PriceListItem>
        </PriceList>
      </Surface>
    )
  }
)

export default ProductModalPriceList
