import { Typography } from '@mui/material'
import { IconListItem } from './styles'

const IconWithLabel = ({ icon, label }) => {
  return (
    <IconListItem>
      {icon}
      <Typography>{label || '-'}</Typography>
    </IconListItem>
  )
}

export default IconWithLabel
