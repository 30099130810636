import { useEffect } from 'react'
import { Button } from '@mui/material'
import { useProductDialog } from 'services/product-dialog'
import { PaginationWrapper } from './styles'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

const ProductModalPagination = () => {
  const { isFirst, isLast, handlePrev, handleNext } = useProductDialog()

  const handleKeyDown = (e) => {
    const activeEl = document?.activeElement
    const inputs = ['input', 'select', 'button', 'textarea']

    if (!activeEl || inputs.indexOf(activeEl.tagName.toLowerCase()) === -1) {
      const keyLeft = e.key === 'ArrowLeft'
      const keyRight = e.key === 'ArrowRight'

      if (keyLeft && !isFirst) {
        handlePrev()
      }
      if (keyRight && !isLast) {
        handleNext()
      }
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', handleKeyDown)
    return () => document.removeEventListener('keyup', handleKeyDown)
  }, [handlePrev, handleNext])

  return (
    <PaginationWrapper>
      <Button
        disabled={isFirst}
        onClick={handlePrev}
        color='secondary'
        startIcon={<ArrowBackIcon />}
      >
        Previous
      </Button>
      <Button
        disabled={isLast}
        onClick={handleNext}
        color='secondary'
        endIcon={<ArrowForwardIcon />}
      >
        Next
      </Button>
    </PaginationWrapper>
  )
}

export default ProductModalPagination
