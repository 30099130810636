import { styled } from '@mui/material'

export const IconListItem = styled('li')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '100%',
  gap: 12,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    flexDirection: 'column',
    textAlign: 'center'
  }
}))
