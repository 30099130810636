import { Paper, Button, Box, IconButton, styled, TextField } from '@mui/material'

export const StickyBar = styled(Paper)(({ theme }) => ({
  paddingInline: 24,
  paddingBlock: 12,
  position: 'sticky',
  bottom: -6,
  zIndex: 99,
  [theme.breakpoints.up('lg')]: {
    marginLeft: -12,
    marginRight: -12
  }
}))

export const StickyBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: 12,
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
    alignItems: 'space-between'
  }
}))

export const DynamicPricingBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}))

export const StyledIconButton = styled(IconButton)({
  fontSize: 32.5
})

export const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: 5,
  '& input': {
    textAlign: 'center',
    paddingRight: '0 !important'
  },
  [theme.breakpoints.down('lg')]: {
    '& input': {
      paddingInline: '0 !important'
    }
  }
}))

export const ReserveButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.pine.main,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main
  }
}))
