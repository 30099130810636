import { Button } from '@mui/material'
import { useCart } from '../../utils/hooks/cart'

const OrderSample = ({ product, notify }) => {
  const { addSample } = useCart({
    reference: product.batch,
    limit: product.bags
  })
  const onOrderSample = () => {
    addSample({
      value: product,
      onSuccess: () => {
        notify({ label: 'Sample added to cart', offerCart: true })
      }
    })
  }

  return (
    <Button
      onClick={onOrderSample}
      variant={'outlined'}
      color={'secondary'}
      target={'_blank'}
    >
      Request sample
    </Button>
  )
}

export default OrderSample
