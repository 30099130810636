import { styled, Box } from '@mui/material'

export const PaginationWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  background: theme.palette.background.main,
  padding: 12,
  position: 'sticky',
  top: 0,
  zIndex: 999,
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    padding: 0,
    marginBottom: 12
  }
}))
