import { Box } from '@mui/material'
import CustomSnackbar from 'components/custom-snackbar'

const ProductModalNotification = ({ notification, setNotification }) => {
  return (
    <Box
      width='auto'
      display='flex'
      position='fixed'
      top={12}
      left={0}
      right={0}
      justifyContent='center'
      zIndex={999}
    >
      <CustomSnackbar
        isOpen={!!notification?.label}
        title={notification?.label}
        offerCart={notification?.offerCart}
        onClose={() => setNotification(null)}
        closeInMs={3500}
      />
    </Box>
  )
}

export default ProductModalNotification
