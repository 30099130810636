import { Dialog as MuiDialog, useTheme } from '@mui/material'
import { DialogContent } from './styles'
import useMediaQuery from '@mui/material/useMediaQuery'

const Dialog = ({ isOpen, closeDialog, dialogContent }) => {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <MuiDialog
      fullScreen={fullScreen}
      scroll='paper'
      maxWidth='md'
      open={isOpen}
      onClose={closeDialog}
    >
      <DialogContent
        sx={{
          '&::-webkit-scrollbar': { display: 'none' }
        }}
      >
        {dialogContent}
      </DialogContent>
    </MuiDialog>
  )
}

export default Dialog
