import { styled, Box } from '@mui/material'

export const ProductSummary = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    padding: 24
  }
}))

export const IconList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  paddingLeft: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  marginBlock: 0,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justfiyContent: 'space-between'
  }
}))
