import { useState, useMemo, useRef } from 'react'
import { Pagination, Autoplay, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { IconButton, useTheme, useMediaQuery } from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { RootGrid, Image } from './styles'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import { SwiperControls } from './styles'

const ProductModalGallery = ({ images = [], fallbackImage }) => {
  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('md'))
  const prevSlide = useRef(null)
  const nextSlide = useRef(null)
  const [swiperRef, setSwiperRef] = useState(null)

  const goToSlideByIndex = (index) => {
    swiperRef.slideTo(index)
  }

  const renderSlides = useMemo(() => {
    if (!images?.length) {
      return (
        <SwiperSlide key={0} onClick={() => goToSlideByIndex(0)}>
          <Image src={fallbackImage} />
        </SwiperSlide>
      )
    }
    return images.map((img, index) => {
      return (
        <SwiperSlide key={index} onClick={() => goToSlideByIndex(index)}>
          <Image src={img} />
        </SwiperSlide>
      )
    })
    // eslint-disable-next-line
  }, [images])

  return (
    <RootGrid style={{ '--swiper-pagination-color': '#FFF' }}>
      <Swiper
        pagination={true}
        loop={renderSlides?.length > 1}
        autoplay={{
          enabled: renderSlides?.length > 1,
          delay: 5000,
          disableOnInteraction: false
        }}
        navigation={{
          enabled: true,
          prevEl: prevSlide.current,
          nextEl: nextSlide.current
        }}
        modules={[Pagination, Autoplay, Navigation]}
        spaceBetween={16}
        onSwiper={(swiper) => setSwiperRef(swiper)}
        style={{
          width: '100%',
          height: mobile ? 375 : 475
        }}
      >
        {renderSlides}
        {renderSlides?.length > 1 && (
          <SwiperControls id='modal-gallery-controls'>
            <IconButton
              size='small'
              sx={{ background: 'rgba(255, 255, 255, 0.5)' }}
              ref={prevSlide}
            >
              <KeyboardArrowLeftIcon />
            </IconButton>
            <IconButton
              size='small'
              sx={{ background: 'rgba(255, 255, 255, 0.5)' }}
              ref={nextSlide}
            >
              <KeyboardArrowRightIcon />
            </IconButton>
          </SwiperControls>
        )}
      </Swiper>
    </RootGrid>
  )
}

export default ProductModalGallery
