import { Grid, styled } from '@mui/material'

export const RootGrid = styled(Grid)(({ theme }) => ({
  overflow: 'hidden',
  '#modal-gallery-controls': {
    opacity: 0,
    transition: 'opacity 350ms ease-in-out'
  },
  '&:hover': {
    '#modal-gallery-controls': {
      opacity: 1
    }
  }
}))

export const Image = styled('img')(({ theme }) => ({
  width: '100%',
  height: '100%',
  objectFit: 'cover'
}))

export const SwiperControls = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 12,
  right: 12,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '100%',
  zIndex: 10,
  pointerEvents: 'none',
  button: {
    pointerEvents: 'initial'
  }
}))
