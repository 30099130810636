import { useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import {
  RemoveCircle as RemoveIcon,
  AddCircle as AddIcon
} from '@mui/icons-material'
import { useCart, EnumCartItemTypes } from '../../utils/hooks/cart'

import {
  Box,
  Typography,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Fade
} from '@mui/material'

import {
  StickyBar,
  StickyBarContainer,
  StyledIconButton,
  StyledTextField,
  ReserveButton,
  DynamicPricingBox
} from './styles'
import InfoIcon from '@mui/icons-material/Info'
import { TransitionGroup } from 'react-transition-group'

const ProductModalAddToCart = ({
  title,
  product,
  step = 1,
  initialQuantity = 10,
  availabilityDate,
  disabled,
  scrollToPricing,
  notify
}) => {
  const {
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    control
  } = useForm({
    defaultValues: {
      quantity: initialQuantity
    }
  })

  const { addToCart, availableQty } = useCart({
    reference: product?.batch,
    limit: product?.bags
  })

  const max = availableQty
  const qtyWatcher = parseInt(watch('quantity'))

  const onIncrease = () => {
    setValue('quantity', +qtyWatcher + step)
  }

  const onDecrease = () => {
    const currentValue = +qtyWatcher
    if (currentValue < 2) return null
    setValue('quantity', qtyWatcher - step)
  }

  const onChangeLimiter = (onChange) => (e) => {
    if (e.target.value < 1 && e.target.value !== '') return null
    onChange(e.target.value)
  }

  const onBlurLimiter = (onBlur) => (e) => {
    if (e.target.value > max) setValue('quantity', max)
    onBlur(e)
  }

  const onSubmit = (values) => {
    addToCart({
      type: EnumCartItemTypes.Spots,
      value: product,
      amount: values.quantity,
      onSuccess: () =>
        notify({ label: `${values.quantity} items added to cart`, offerCart: true })
    })
  }

  const dynamicPricing = useMemo(() => {
    if (!qtyWatcher) return ''
    const priceList = []
    for (const [key, value] of Object.entries(product.pricingData.pricesFormatted)) {
      priceList.push({ qty: key, label: value })
    }
    const minQtyAvailable = priceList.filter(({ qty }) => qty <= qtyWatcher)
    const bestPrice = minQtyAvailable[minQtyAvailable.length - 1]
    return `${bestPrice.qty}+ bags - ${bestPrice.label}`
  }, [qtyWatcher])

  return (
    <StickyBar elevation={5}>
      <StickyBarContainer>
        <DynamicPricingBox>
          <Typography variant='h3'>{title}</Typography>
          <Box display='flex' gap={0} alignItems='center'>
            <TransitionGroup component={null} exit={false}>
              <Fade timeout={750} key={dynamicPricing}>
                <Typography>{dynamicPricing}</Typography>
              </Fade>
            </TransitionGroup>
            <IconButton size='small' onClick={scrollToPricing}>
              <InfoIcon fontSize='small' />
            </IconButton>
          </Box>
        </DynamicPricingBox>
        <Box
          display='flex'
          alignItems='center'
          component={'form'}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid container spacing={2} alignItems='center'>
            <Grid
              item
              xs={6}
              alignItems={'center'}
              component={FormControl}
              error={!!errors['quantity']}
            >
              <Grid
                item
                xs={3}
                container
                alignItems={'center'}
                justifyContent={'center'}
              >
                <StyledIconButton onClick={onDecrease}>
                  <RemoveIcon fontSize={'inherit'} color={'text'} />
                </StyledIconButton>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  control={control}
                  rules={{
                    max: {
                      value: max,
                      message:
                        max === 0
                          ? 'all available quantities already in the cart'
                          : `maximum available amount is ${max}`
                    },
                    min: {
                      value: 1,
                      message:
                        max === 0
                          ? 'all available quantities already in the cart'
                          : "quantity can't be less then 1"
                    }
                  }}
                  name={'quantity'}
                  render={({ field: { value, onChange, onBlur } }) => (
                    <StyledTextField
                      min={1}
                      type={'number'}
                      value={value}
                      onChange={onChangeLimiter(onChange)}
                      onBlur={onBlurLimiter(onBlur)}
                    />
                  )}
                />
                {errors['quantity'] ? (
                  <Grid item xs={12}>
                    <FormHelperText mt={0}>
                      {errors['quantity']?.message}
                    </FormHelperText>
                  </Grid>
                ) : null}
              </Grid>
              <Grid
                item
                xs={3}
                container
                alignItems={'center'}
                justifyContent={'center'}
              >
                <StyledIconButton onClick={onIncrease}>
                  <AddIcon fontSize={'inherit'} color={'text'} />
                </StyledIconButton>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {availabilityDate ? (
                <ReserveButton
                  type={'submit'}
                  color={'pine'}
                  variant={'contained'}
                  fullWidth
                  disabled={disabled}
                >
                  Reserve
                </ReserveButton>
              ) : (
                <Button
                  type={'submit'}
                  variant={'contained'}
                  color='teal'
                  fullWidth
                  disabled={disabled}
                >
                  Add to cart
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </StickyBarContainer>
    </StickyBar>
  )
}

export default ProductModalAddToCart
