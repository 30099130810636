const colors = {
  background: {
    default: '#F3EFE6',
    main: '#F3EFE6',
    contrastText: '#37493B'
  },
  primary: {
    main: '#FF7A59',
    contrastText: '#ffffff'
  },
  secondary: {
    main: '#37474F',
    contrastText: '#ffffff'
  },
  tertiary: {
    main: '#D1EEDF',
    contrastText: '#37493B'
  },
  peach: {
    main: '#F58E81',
    contrastText: '#ffffff'
  },
  ocean: {
    main: '#4CB6B6',
    contrastText: '#ffffff'
  },
  stone: {
    main: '#7EA4B7',
    contrastText: '#ffffff'
  },
  pine: {
    main: '#AF9631',
    contrastText: '#ffffff'
  },
  chocolat: {
    main: '#700f0d',
    contrastText: '#ffffff'
  },
  forest: {
    main: '#366665',
    contrastText: '#ffffff'
  },
  thyme: {
    main: '#69c99b',
    contrastText: '#ffffff'
  },
  thyme1: {
    main: '#60B791',
    contrastText: '#ffffff'
  },
  thyme2: {
    main: '#5AAC8B',
    contrastText: '#ffffff'
  },
  thyme3: {
    main: '#509880',
    contrastText: '#ffffff'
  },
  thyme4: {
    main: '#478676',
    contrastText: '#ffffff'
  },
  thyme5: {
    main: '#3F776E',
    contrastText: '#ffffff'
  },
  sand: {
    main: '#EAE4D4',
    dark: '#c3c1ba',
    contrastText: '#ffffff'
  },
  navy: {
    main: '#2D3958',
    dark: '#27324C',
    contrastText: '#ffffff'
  },
  teal: {
    main: '#006864',
    contrastText: '#FFF'
  },
  navigation: {
    main: '#006864',
    mainTransparent: 'rgba(0, 104, 100, 0.5)',
    active: '#4CB6B6',
    contrastText: '#fff'
  },
  text: {
    main: '#37474F',
    primary: '#37474F'
  },
  white: {
    main: '#ffffff',
    contrastText: '#37474F'
  },
  black: {
    main: '#37474F',
    contrasText: '#ffffff'
  }
}

export default colors
