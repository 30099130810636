import { IconButton } from '@mui/material'
import ShareIcon from '@mui/icons-material/Share'

export const NativeShare = ({ title, pbo, notify }) => {
  if (!pbo) return null
  const handleShare = async () => {
    const shareUrl = `${window.location.origin}/spotlist/${pbo}`
    if (navigator?.share) {
      await navigator
        .share({
          title,
          text: `Have a look at ${title} - ${pbo}`,
          url: shareUrl
        })
        .then(() => notify({ label: `You have shared ${pbo}` }))
        .catch((error) => console.log(error))
    } else {
      navigator.clipboard.writeText(shareUrl)
      notify({ label: `Copied to clipboard: ${shareUrl}` })
    }
  }

  return (
    <IconButton onClick={handleShare} sx={{ background: '#FFF' }}>
      <ShareIcon />
    </IconButton>
  )
}

export default NativeShare
