import { Fade, Typography, Button } from '@mui/material'
import { useCallback, useEffect, useState } from 'react'

import { CustomSnackbarRoot } from './styles'
import { iconsSet } from './iconsSet'
import { fonts } from 'constants/fonts'

let textTimout = null

const CustomSnackbar = ({
  isOpen,
  variant = 'success',
  timing = 1000,
  onClose,
  title,
  offerCart,
  closeInMs = 2000
}) => {
  const [text, setText] = useState('')
  const Icon = iconsSet[variant]?.icon

  const onCloseHandler = useCallback(() => {
    onClose && onClose()
    textTimout = setTimeout(() => setText(''), timing)
  }, [onClose, timing])

  useEffect(() => {
    const closeTimeout = setTimeout(onCloseHandler, closeInMs)
    return () => clearTimeout(closeTimeout)
  }, [isOpen, onClose, onCloseHandler, closeInMs])

  useEffect(() => {
    if (title) {
      clearTimeout(textTimout)
      setText(title)
    }
  }, [title])

  return (
    <Fade in={isOpen} timeout={timing} unmountOnExit>
      <CustomSnackbarRoot elevation={5}>
        {Icon ? Icon : null}
        <Typography fontFamily={fonts.WalsheimTrial} fontWeight={500}>
          {text}
        </Typography>
        {offerCart && (
          <Button sx={{ fontWeight: 600 }} href='/cart'>
            View cart
          </Button>
        )}
      </CustomSnackbarRoot>
    </Fade>
  )
}

export default CustomSnackbar
