import { Paper, styled } from '@mui/material'

export const CustomSnackbarRoot = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: 5,
  padding: 11,
  display: 'flex',
  alignItems: 'center'
}))

export const StyledCheckIconRoot = styled('div')(({ theme }) => ({
  width: 21,
  height: 21,
  backgroundColor: theme.palette.tertiary.main,
  borderRadius: '50%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 17,
  marginRight: 12
}))
