import { useMemo } from 'react'
import { useUserData } from 'services/auth/index'
import { Box, Typography, Chip, Divider, Button } from '@mui/material'
import OrderSample from 'components/order-sample/OrderSample'
import { IconList, ProductSummary } from './styles'
import { NEW_LINE_CHARACTER } from 'constants/email'
import IconWithLabel from 'components/icon-with-label/IconWithLabel'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid'
import TerrainIcon from '@mui/icons-material/Terrain'
import GrainIcon from '@mui/icons-material/Grain'
import { parseDate } from 'utils/helpers/date'

const ProductModalSummary = ({
  product,
  introduction,
  cuppingScoreChip,
  certificates,
  notify
}) => {
  const {
    location,
    batch,
    region_with_grade,
    origin,
    altitude,
    variety,
    media_url,
    bags,
    is_sample_available,
    availability_date,
    process
  } = product

  const pbo = batch
  const { userData } = useUserData()

  const requestViaEmail = useMemo(() => {
    let email = 'trabocca.marketing@trabocca.com'
    const USAEmail = 'mytrabocca-us@trabocca.com'
    const subject = 'Request for media'
    const body = `Request for media for:${NEW_LINE_CHARACTER} Coffee + Region and Grade: ${location.name} / ${region_with_grade}${NEW_LINE_CHARACTER}Process and PBO nr: ${process} / ${pbo}`

    if (userData?.addresses?.find((address) => address.country === 'US')) {
      email = USAEmail
    }

    return `mailto:${email}?subject=${subject}&body=${body}`
  }, [userData])

  const renderCertificates = useMemo(() => {
    if (!certificates?.length) return null
    return (
      <Box display='flex' alignItems='center' gap={1}>
        {certificates.map((item) => (
          <Chip size='small' color='primary' key={item} label={`${item}`} />
        ))}
      </Box>
    )
  }, [certificates])

  const availabilityDate = useMemo(() => {
    if (!availability_date) return null
    return parseDate(availability_date.replace(/ /g, 'T'), 'MMM YYYY')
  }, [availability_date])

  const introductionText = introduction.replaceAll('\\n', '\n')

  return (
    <ProductSummary display='flex' flexDirection='column' gap={1} minWidth={380}>
      <Box>
        <Typography variant='h2'>{location.name}</Typography>
        <Box display='flex' gap={1}>
          <Typography fontWeight={600}>{origin}</Typography>
          <Typography>{pbo}</Typography>
        </Box>
      </Box>

      <Box display='flex' gap={1} alignItems='center' mt={1} mb={1}>
        <Box
          width={10}
          height={10}
          sx={{
            bgcolor: availability_date ? 'pine.main' : 'success.main',
            borderRadius: '50%'
          }}
        />
        <Typography>Availability: {bags} bags</Typography>
        {availabilityDate && <Typography>({availabilityDate})</Typography>}
      </Box>

      {introduction && (
        <Typography sx={{ whiteSpace: 'pre-line' }}>{introductionText}</Typography>
      )}
      <Box display='flex' gap={1} mt={2} mb={1}>
        {cuppingScoreChip}
        {renderCertificates}
      </Box>

      <Divider sx={{ marginBlock: 1 }} />
      <IconList gap={2}>
        <IconWithLabel icon={<LocationOnIcon />} label={`${region_with_grade}`} />
        <IconWithLabel icon={<FlipCameraAndroidIcon />} label={process} />
        <IconWithLabel icon={<TerrainIcon />} label={altitude} />
        <IconWithLabel icon={<GrainIcon />} label={variety} />
      </IconList>

      <Divider sx={{ marginBlock: 1 }} />
      <Box display='flex' gap={1.5}>
        <Button
          href={media_url || requestViaEmail}
          variant={'outlined'}
          color={'secondary'}
          target={'_blank'}
        >
          {media_url ? 'Download media' : 'Request media'}
        </Button>
        {is_sample_available && <OrderSample product={product} notify={notify} />}
      </Box>
    </ProductSummary>
  )
}

export default ProductModalSummary
