import {
  createContext,
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect
} from 'react'
import GlobalModal from '../../components/global-modal'

const defaultValue = {
  title: '',
  content: 'text',
  severity: '',
  showCloseButton: false,
  showCloseIcon: true,
  width: 500,
  openModal: () => null
}
const ModalContext = createContext(defaultValue)

export const ModalProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState()
  const [title, setTitle] = useState(defaultValue.title)
  const [content, setContent] = useState(defaultValue.controls)
  const [severity, setSeverity] = useState(defaultValue.severity)
  const [width, setWidth] = useState(defaultValue.width)
  const [showCloseButton, setShowCloseButton] = useState(
    defaultValue.showCloseButton
  )
  const [showCloseIcon, setShowCloseIcon] = useState(defaultValue.showCloseIcon)

  const toggleModal = () => {
    setShowCloseButton(false)
    setSeverity('')
    setWidth(defaultValue.width)
    setIsOpen(!isOpen)
  }

  const openModal = useCallback(
    ({
      title: newTitle,
      content: newContent,
      severity: newSeverity,
      showCloseButton: newShowCloseButton,
      showCloseIcon,
      width: newWidth
    }) => {
      if (newTitle) setTitle(newTitle)
      if (newContent) setContent(newContent)
      if (newSeverity) setSeverity(newSeverity)
      if (newShowCloseButton) setShowCloseButton(newShowCloseButton)
      if (newWidth) setWidth(newWidth)
      setShowCloseIcon(showCloseIcon)
      setIsOpen(true)
    },
    []
  )

  const closeModal = () => {
    setIsOpen(false)
    setSeverity('')
    setShowCloseButton(false)
    setWidth(defaultValue.width)
    setTitle('')
    setContent(null)
  }

  const value = useMemo(() => {
    return {
      openModal,
      closeModal,
      isOpen,
      severity
    }
  }, [openModal, closeModal, isOpen, severity])

  return (
    <ModalContext.Provider value={value}>
      <GlobalModal
        toggleModal={toggleModal}
        isOpen={isOpen}
        title={title}
        content={content}
        showCloseButton={showCloseButton}
        showCloseIcon={showCloseIcon}
        width={width}
      />
      {children}
    </ModalContext.Provider>
  )
}

export const useModal = (onClose) => {
  const cxt = useContext(ModalContext)
  const { isOpen } = cxt

  useEffect(() => {
    return () => {
      if (isOpen) {
        onClose && onClose()
      }
    }
  }, [isOpen, onClose])

  return cxt
}
