import { Box, Grid, Typography } from '@mui/material'
import { Divider } from '../../../node_modules/@mui/material/index'

const ProductModalDetailsRow = ({ label, value }) => {
  return (
    <>
      <Divider />
      <Box mt={1} mb={1} maxWidth='100%'>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography>{label}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography sx={{ fontWeight: 600 }}>{value || '-'}</Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export const ProductModalDetails = ({ product, certificates }) => {
  const {
    crop_year,
    screen_size,
    packing_type,
    pack_weight,
    pack_unit,
    origin,
    region_with_grade,
    altitude,
    variety,
    process,
    warehouse,
    score
  } = product

  return (
    <Box pl={3} pr={3} pt={1} pb={1}>
      <Typography variant='h3' mb={1.5}>
        Details
      </Typography>
      <Grid container columnSpacing={6}>
        <Grid item xs={12} lg={6}>
          <ProductModalDetailsRow label='Crop year' value={crop_year} />
          <ProductModalDetailsRow
            label='Certification'
            value={certificates.length ? certificates.join(', ') : ''}
          />
          <ProductModalDetailsRow label='Screen size' value={screen_size} />
          <ProductModalDetailsRow
            label='Packaging Quantity'
            value={pack_weight + pack_unit}
          />
          <ProductModalDetailsRow label='Packaging Type' value={packing_type} />
          <ProductModalDetailsRow label='Warehouse' value={warehouse.title} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <ProductModalDetailsRow label='Origin' value={origin} />
          <ProductModalDetailsRow label='Region + Grade' value={region_with_grade} />
          <ProductModalDetailsRow label='Altitude' value={altitude} />
          <ProductModalDetailsRow label='Variety' value={variety} />
          <ProductModalDetailsRow label='Process' value={process} />
          <ProductModalDetailsRow label='Cup score' value={score} />
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProductModalDetails
