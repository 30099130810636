import { Typography, Button } from '@mui/material'
import { Surface } from './styles'

const ProductModalAbout = ({ location }) => {
  if (!location?.content) return null
  return (
    <Surface>
      <Typography variant='h3' mb={1.5}>
        About
      </Typography>
      <Typography mb={2.5}>{location.content}</Typography>
      <Button
        href={location.url}
        variant={'outlined'}
        color={'secondary'}
        target={'_blank'}
      >
        Learn more
      </Button>
    </Surface>
  )
}

export default ProductModalAbout
