import { styled } from '@mui/material'

export const Surface = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.sand.main,
  padding: 24
}))

export const PriceList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  paddingLeft: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  }
}))

export const PriceListItem = styled('li')(({ theme }) => ({}))
