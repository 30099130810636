import { createContext, useState, useMemo, useContext, useEffect } from 'react'
import Dialog from '../../components/dialog/Dialog'

const defaultValue = {
  isOpen: false,
  dialogContent: null,
  product: null,
  productList: null
}

const ProductDialogContext = createContext(defaultValue)

export const ProductDialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(defaultValue.isOpen)
  const [dialogContent, setDialogContent] = useState(defaultValue.dialogContent)
  const [product, setproduct] = useState(defaultValue.product)
  const [productList, setproductList] = useState(defaultValue.productList)

  const openDialog = ({ content, data, dataList }) => {
    setDialogContent(content)
    setproduct(data)
    if (dataList) setproductList(dataList)
    setIsOpen(true)
  }

  const updateDialog = (data) => {
    setproduct(data)
  }

  const closeDialog = () => {
    setIsOpen(false)
    setTimeout(() => {
      setDialogContent(defaultValue.dialogContent)
      setproduct(defaultValue.product)
      setproductList(defaultValue.productList)
    }, 100)
  }

  const activeIndex = useMemo(() => {
    if (!productList?.length) return 0
    const index = productList?.findIndex((prod) => prod?.batch === product?.batch)
    return index
  }, [product, productList])

  const isFirst = useMemo(() => {
    if (!productList?.length) return true
    return activeIndex === 0
  }, [activeIndex, productList?.length])

  const isLast = useMemo(() => {
    if (!productList?.length) return true
    return activeIndex === productList?.length - 1
  }, [activeIndex, productList?.length])

  const handlePrev = () => {
    if (!isFirst) {
      updateDialog(productList?.[activeIndex - 1])
    }
  }
  const handleNext = () => {
    if (!isLast) {
      updateDialog(productList?.[activeIndex + 1])
    }
  }

  return (
    <ProductDialogContext.Provider
      value={{
        openDialog,
        closeDialog,
        updateDialog,
        handlePrev,
        handleNext,
        isFirst,
        isLast,
        isOpen,
        product,
        productList
      }}
    >
      <Dialog
        isOpen={isOpen}
        closeDialog={closeDialog}
        dialogContent={dialogContent}
        product={product}
      />
      {children}
    </ProductDialogContext.Provider>
  )
}

export const useProductDialog = (onClose) => {
  const ctx = useContext(ProductDialogContext)
  const { isOpen } = ctx

  useEffect(() => {
    return () => {
      if (isOpen) {
        onClose && onClose()
      }
    }
  }, [isOpen, onClose])

  return ctx
}
